@charset "utf-8";
@import url('bootstrap.min.css'); 


@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
body {
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    color: rgb(58, 58, 58);
    font-weight: 400;
    font-family: Mulish, sans-serif !important;
}

* {
    margin: 0px;
    padding: 0px;
}

.clearfix {
}



.clearfix::before, .clearfix::after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix::after {
    clear: both;
}

.clr, .clear {
    clear: both;
}

header, footer, aside, article, section, detail, nav, figure {
    position: relative;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
    padding: 0px;
}

ul, li, ol {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover, a:focus {
    outline: none;
    text-decoration: none !important;
}

img {
    border: 0px;
    outline: none;
    max-width: 100%;
}

input[type="text"], input[type="password"], select, textarea, input[type="button"] {
    max-width: 100%;
}

table {
    width: 100%;
}

p {
    margin: 0px;
    padding: 0px;
}

.fltL {
    float: left;
}

.fltR {
    float: right;
}

.padding_none {
    padding: 0px !important;
}

.border_none {
    border: none !important;
}

.navbar-toggle {
    background-color: rgb(255, 255, 255) !important;
}

.icon-bar {
    background-color: rgb(22, 88, 133) !important;
}

.p_right0 {
    padding-right: 0px !important;
}

.p_left0 {
    padding-left: 0px !important;
}

.m_bottom0 {
    margin-bottom: 0px !important;
}

input[type="date"] {
    text-transform: uppercase !important;
}

.Green {
    color: rgb(55, 167, 19);
    margin: 0px 0px 0px 10px;
}

.Red {
    color: rgb(226, 24, 24);
}

.Blue {
    color: rgb(22, 88, 133);
}

.Yellow {
    color: rgb(254, 202, 5);
}

.Pink {
    color: rgb(253, 84, 109);
}

.Button {
    border: none;
    padding: 10px 30px;
    text-align: center;
    transition: all 0.5s ease-in-out 0s;
    position: relative;
    font-size: 14px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: none;
    display: inline-block;
    background-color: rgb(22, 88, 133) !important;
    outline: 0px !important;
}

.Button:hover {
    color: rgb(255, 255, 255);
    background: rgb(0, 0, 0) !important;
}

.Button.Cancel {
    color: black;
    margin: 0px 0px 0px 10px;
    padding: 10px 20px;
    text-decoration: none;
    background: rgb(217, 217, 217) !important;
}

.switch {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0px;
    padding: 2px 0px 3px 50px;
    font-weight: 400;
    font-size: 12px;
    color: green;
}

.switch input {
    opacity: 0;
    width: 0px;
    height: 0px;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    inset: 0px;
    background-color: rgb(255, 255, 255);
    transition: all 0.4s ease 0s;
    height: 21px;
    border-radius: 50px;
    width: 40px;
    border: 1px solid rgb(210, 197, 224);
    min-width: auto;
    background-color: white;
    border-color: #0e518d;
}

.switch .slider::before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 3px;
    bottom: 0px;
    top: 0px;
    transition: all 0.4s ease 0s;
    border-radius: 50%;
    margin: auto;
    background-image: linear-gradient(45deg, #0e518d, #0e518d);
}

.switch input:checked + .slider {
    background-color: #0e518d;
    border-color: #0e518d;
}

.switch input:checked + .slider::before {
    transform: translateX(19px);
    background: #fff;
}
.switch span{font-weight: 600;}
.CheckBox {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    color: rgb(45, 45, 45);
    min-height: 20px;
    font-weight: 600 !important;
    margin: 0px !important;
}

.CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0px;
    width: 0px;
}

.CheckBox .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 24px;
    background-color: transparent;
    border: 1px solid rgb(210, 197, 224);
    border-radius: 2px;
}

.CheckBox:hover input ~ .checkmark {
    background: rgb(22, 88, 133);
    border-color: rgb(22, 88, 133);
}

.CheckBox input:checked ~ .checkmark {
    background: rgb(22, 88, 133);
    border-color: rgb(22, 88, 133);
}

.CheckBox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 8px;
    top: 2px;
    width: 5px;
    height: 11px;
    border-style: solid;
    border-color: white;
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
}

.CheckBox input:checked ~ .checkmark::after {
    display: block;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.GuestAccordian .card .card-header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0;
    border: none;
    cursor: pointer;
}

.GuestAccordian .card .card-header h5 {
    font-weight: 700;
    color: #3c3c3c;
    font-size: 16px;
}

.GuestAccordian .card .card-header h6 {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    color: #000;
}

.GuestAccordian .card .card-header h6 span {
    display: block;
    color: #767676;
    padding-top: 10px;
}

.GuestAccordian .card .card-header::after {
    content: '\f106';
    font-family: FontAwesome;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: all 0.5s ease-in-out 0s;
}

::after{
    box-sizing: border-box;
}


.collapse:not(.show) {
    display: none;
}

.GuestAccordian .card .card-body {
    border-top: 1px solid #ddd;
}

.card-body {
    min-height: 1px;
    padding: 1.25rem;
}

.GuestUserBox {
    background-color: #f7f7f7;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    margin: 0 0 20px 0;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.GuestUserBox figcaption {
    width: calc(100% - 65px);
    padding: 0 0 0 10px;
}

.GuestUserBox figcaption .GuestUserHead {
    display: flex;
    position: relative;
}

.GuestAccordian .card .card-header[aria-expanded="true"]::after {
    transform: rotate(180deg);
}

.GuestAccordian .card .card-header::after {
    content: '\f106';
    font-family: FontAwesome;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: all 0.5s ease-in-out 0s;
}

.Radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    margin: 0px !important;
}

.Radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.Radio .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 22px;
    width: 22px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    border: 1px solid rgb(210, 197, 224);
}

.Radio:hover input ~ .checkmark {
    border-color: rgb(210, 197, 224);
    background-image: linear-gradient(45deg, rgb(244, 150, 27), rgb(215, 164, 74));
}

.Radio input:checked ~ .checkmark {
    border-color: rgb(210, 197, 224);
    background-image: linear-gradient(45deg, rgb(244, 150, 27), rgb(215, 164, 74));
}

.Radio .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.Radio input:checked ~ .checkmark::after {
    display: block;
}

.Radio .checkmark::after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    inset: 0px;
    margin: auto;
}

.LoginArea {
    background-image: linear-gradient(45deg, rgb(227, 185, 88), rgb(223, 169, 45));
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.LoginArea::before {
    content: "";
}

.LoginArea::after {
    content: "";
}

.LoginArea .LoginBox {
    background-color: rgb(255, 255, 255);
    padding: 30px;
    box-shadow: rgb(221, 221, 221) 0px 0px 10px;
    border-radius: 20px;
    width: 530px;
    position: relative;
    border: 0px solid rgb(242, 169, 4);
}

.LoginArea .LoginBox figure {
    text-align: center;
    background-color: rgb(255, 255, 255);
    margin: 0px 0px 20px;
}

.LoginArea .LoginBox figure img {
    width: 80px;
}

.LoginArea .LoginBox figure .congradulation {
    background: none;
    width: 70px;
}

.LoginArea .LoginBox form {
    margin: auto;
}

.LoginArea .LoginBox form h3 {
    font-size: 23px;
    margin: 0px 0px 30px;
    font-weight: 800;
    color: rgb(0, 0, 0);
    text-align: center;
}

.LoginArea .LoginBox form h5 {
    font-size: 15px;
    margin: -19px 0px 40px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 25px;
}

.LoginArea .LoginBox form p {
    font-size: 13px;
    margin: 0px 0px 20px;
    font-weight: 600;
    text-align: center;
}

.LoginArea .LoginBox form .form-group {
    margin: 0px 0px 20px;
    position: relative;
}

.LoginArea .LoginBox form .form-group label {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 700;
    margin: 0px 0px 10px;
    text-transform: capitalize;
}

.LoginArea .LoginBox form .form-group label a {
    float: right;
}

.LoginArea .LoginBox form .form-group .form-control {
    box-shadow: none;
    border: 1px solid rgb(221, 221, 221);
    padding: 10px 15px;
    height: auto;
    font-size: 15px;
    color: rgb(0, 0, 0);
    border-radius: 6px;
}

.LoginArea .LoginBox form .form-group .form-control:focus {
    border-color: rgb(210, 197, 224);
}

.LoginArea .LoginBox form .form-group .Icon {
    position: absolute;
    top: 39px;
    right: 15px;
    font-size: 16px;
}

.LoginArea .LoginBox form .form-group .Checkboxs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LoginArea .LoginBox form .form-group .Checkboxs a {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px;
    color: rgb(237, 27, 36);
}

.LoginArea .LoginBox form .form-group .Checkboxs a:hover {
    color: black;
}

.LoginArea .LoginBox form .form-group .OTPBox {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 10px;
}

.LoginArea .LoginBox form .form-group .OTPBox .form-control {
    padding: 0px;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 30px;
    border-color: #959191; 
}

.LoginArea .LoginBox form .Button {
    display: block;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    font-size: 16px;
    border-radius: 60px;
}

.LoginArea .LoginBox h6 {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
    color: rgb(22, 88, 133);
}

.LoginArea .LoginBox h6 a {
    color: rgb(210, 197, 224);
    font-weight: 700;
}

.form-group-checkBox {
    display: flex;
    justify-content: space-between;
}

.check {
    display: flex;
    align-items: baseline;
}

a:not([href]):not([class]) {
    color: rgb(6, 77, 137);
}

.SuccessArea {
    background-image: linear-gradient(45deg, rgb(244, 150, 27), rgb(215, 164, 74));
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.SuccessArea .SuccessBox {
    background-color: rgb(255, 255, 255);
    min-width: 525px;
    text-align: center;
    padding: 60px 50px;
    border-radius: 20px;
}

.SuccessArea .SuccessBox span {
    width: 200px;
    display: block;
    margin: 0px auto 30px;
}

.SuccessArea .SuccessBox h3 {
    font-size: 36px;
    font-weight: 800;
    margin: 0px 0px 10px;
    color: rgb(22, 88, 133);
}

.SuccessArea .SuccessBox p {
    font-size: 15px;
    font-weight: 600;
    margin: 0px 0px 20px;
}

.SuccessArea .SuccessBox a {
    display: inline-block;
    padding: 12px 30px;
    background: rgb(6, 77, 137);
    border-radius: 5px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
}

.Header {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    position: fixed;
    width: calc(100% - 235px);
    left: 235px;
    top: 0px;
    z-index: 99;
    background-color: rgb(22, 88, 133);
}

.Header .Logo {
    width: 235px;
    text-align: center;
    padding: 14px 0px;
    float: left;
    height: 60px;
    background-color: rgb(255, 255, 255);
}

.Header .Logo img {
    width: 140px;
}

.Header .Navigation {
    transition: all 0.2s ease 0s;
    width: 100%;
    text-align: right;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.Header .Navigation .Bell {
    display: inline-block;
    margin: 0px 30px 0px 0px;
}

.Header .Navigation .Bell a {
    color: rgb(255, 255, 255);
    font-size: 18px;
}

.Header .Navigation .Avater {
    position: relative;
    display: inline-block;
}

.Header .Navigation .Avater a {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: rgb(255, 255, 255);
    padding: inherit;
    font-weight: 600;
}

.Header .Navigation .Avater a figure {
    width: 40px;
    border-radius: 50%;
    margin: 0px 15px 0px 0px;
    overflow: hidden;
    height: 40px;
    border: 2px solid rgb(255, 255, 255);
    background: white;
}

.Header .Navigation .Avater ul {
    position: absolute;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 11px 0px;
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0px 0px 0px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease 0s;
    z-index: 99;
    top: 100%;
    text-align: left;
    min-width: 200px;
    display: block;
    width: auto;
}

.Header .Navigation .Avater ul li {
    margin: 0px;
    border-bottom: 1px solid rgb(210, 211, 212);
}

.Header .Navigation .Avater ul li figure {
    background: white;
}

.Header .Navigation .Avater ul li:first-child {
    background: rgb(22, 88, 133);
    position: relative;
    padding: 15px 10px 15px 60px;
    text-align: left;
    border: 2px solid rgb(255, 255, 255);
}

.Header .Navigation .Avater ul li figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    border: 2px solid rgb(255, 255, 255);
    overflow: hidden;
    margin: 0px;
}

.Header .Navigation .Avater ul li h4 {
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 18px;
}

.Header .Navigation .Avater ul li h4 span {
    font-size: 11px;
    font-weight: 400;
    display: block;
}

.Header .Navigation .Avater ul li a {
    color: rgb(78, 78, 78);
    padding: 10px 20px;
    font-weight: 400;
    transition: all 0.5s ease-in-out 0s;
}

.Header .Navigation .Avater ul li a span {
    margin: 0px 10px 0px 0px;
}

.Header .Navigation .Avater ul li a:hover {
    background-color: rgb(22, 88, 133);
    color: rgb(255, 255, 255);
}

.Header .Navigation .Avater:hover ul {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}

.SidenavBar {
    position: fixed;
    width: 235px;
    top: 0px;
    padding: 0px;
}

.SidenavBar .Logo {
    padding: 10px 0px;
    border-bottom: 1px solid rgb(221, 221, 221);
    margin: 0px;
}

.SidenavBar .Logo img {
    width: 116px;
    margin: auto;
    display: block;
    background: white;
}

.SidenavBar ul::-webkit-scrollbar-track {
    background: rgb(187, 187, 187);
    border-radius: 0px;
}

.SidenavBar ul::-webkit-scrollbar-thumb {
    background-color: rgb(22, 88, 133);
    border-radius: 0px;
    transition: background-color 0.2s linear 0s, width 0.2s ease-in-out 0s;
}

.SidenavBar ul::-webkit-scrollbar {
    width: 7px;
    border-radius: 0px;
}

.SidenavBar ul {
    margin: 0px;
    height: 100vh;
    overflow: auto;
}

.SidenavBar ul li {
    margin: 0px;
}

.SidenavBar ul li a {
    display: block;
    cursor: pointer;
    padding: 13px 20px 13px 45px;
    transition: all 0.5s ease-in-out 0s;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
    border-bottom: 1px solid rgb(221, 221, 221);
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.SidenavBar ul li a span {
    font-size: 15px;
    display: inline-block;
    position: absolute;
    top: 13px;
    left: 20px;
}

.SidenavBar ul li.active a {
    background-color: rgb(223, 169, 45);
    color: rgb(255, 255, 255);
}

.SidenavBar ul li a:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
}

.SidenavBar ul li a.dropdown-toggle::after {
    content: "ÃƒÆ’Ã‚Â¯ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¾ÃƒÂ¢Ã¢â€šÂ¬Ã‚Â¡";
    font-family: FontAwesome;
    position: absolute;
    top: 18px;
    right: 10px;
    font-size: 15px;
    border: none;
    font-weight: 700;
    line-height: 10px;
}

.SidenavBar ul li ol {
    float: none;
    border: none;
    margin: 0px;
    padding: 0px;
    position: relative !important;
    transform: inherit !important;
}

.SidenavBar ul li.active ol {
    display: block;
}

.SidenavBar ul li.active ol li a {
    background: transparent;
    color: rgb(22, 88, 133) !important;
}

.SidenavBar ul li.active ol li a:hover {
    color: rgb(249, 140, 28) !important;
}

.WrapperArea {
    margin-left: 235px;
    flex: 1 1 auto;
    width: calc(100% - 235px);
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    transition: all 0.5s ease-in-out 0s;
}

.WrapperArea .WrapperBox {
    padding: 20px;
    background-color: rgb(239, 239, 239);
    min-height: calc(100vh - 60px);
}

.Small-Wrapper {
    background-color: rgb(255, 255, 255);
    padding: 25px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 10px;
}

.Small-Wrapper + .Small-Wrapper {
    margin-top: 20px;
}

.StaticBoxArea .panel-group {
    margin: 0px;
}

.StaticBoxArea .panel-group .panel {
    border: 1px solid rgb(233, 233, 233);
    border-radius: 0px;
}

.StaticBoxArea .panel-group .panel + .panel {
    margin: 20px 0px 0px;
}

.StaticBoxArea .panel-group .panel .panel-heading {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
    border-radius: 0px;
    padding: 15px 55px 15px 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.StaticBoxArea .panel-group .panel .panel-heading::after {
    content: '\f105';
    font-family: FontAwesome;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: all 0.5s ease-in-out 0s;
}

.StaticBoxArea .panel-group .panel .panel-heading[aria-expanded="true"]::after {
    transform: rotate(90deg);
}

.StaticBoxArea .panel-group .panel .panel-heading h4 {
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.StaticBoxArea .panel-group .panel .panel-heading h4 span {
    padding-right: 10px;
}

.StaticBoxArea .panel-group .panel .panel-heading a {
    color: rgb(22, 88, 133);
    font-size: 14px;
}

.StaticBoxArea .panel-group .panel .panel-body {
    padding: 15px;
}

.StaticBoxArea .panel-group .panel .panel-body h3 {
    color: rgb(22, 88, 133);
    font-size: 16px;
}

.StaticBoxArea .panel-group .panel .panel-body::before {
    content: none;
}

.StaticBoxArea .panel-group .panel .panel-body p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(64, 64, 64);
    line-height: 22px;
}

.StaticBoxArea .panel-group .panel .panel-body p + p {
    margin-top: 15px;
}

.StaticBoxArea .panel-group .panel .panel-body h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 50px;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item figure {
    width: 60px;
    margin: 0px;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item aside {
    margin: 0px 0px 0px 15px;
}

.StaticBoxArea .panel-group .panel .panel-body ul {
    display: flex;
}

.StaticBoxArea .panel-group .panel .panel-body ul li {
    width: 50%;
    border-radius: 5px;
    border: 1px solid rgb(237 237 237);
    padding: 10px;
    margin: 0 15px 0 0;
}

.TitleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 0px 20px;
}

.TitleBox .add {
    background: rgb(22, 88, 133);
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    display: inline-block;
}

.TitleBox .add2 {
    background: rgb(22, 88,133);
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    display: inline-block;
}

h4.Title {
    margin: 0px;
    font-weight: 800;
    text-transform: capitalize;
    font-size: 22px;
    color: rgb(22, 88, 133);
}

h3.Title {
    font-size: 20px;
    color: rgb(192, 28, 45);
}

a.TitleLink {
    background-image: linear-gradient(45deg, rgb(5, 6, 6), rgb(22, 88, 133));
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}

a.TitleLink:hover {
    background-color: rgb(0, 0, 0);
}

.SearchBox {
    margin: 0px;
    float: right;
    display: flex;
}

.SearchBox form {
    margin: 0px 10px 0px 0px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.SearchBox form input {
    margin: 0px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: none;
    outline: 0px;
    padding: 0px 13px;
    height: 43px;
    min-width: 225px;
    border-radius: 5px 0px 0px 5px;
    font-weight: 600;
}

.SearchBox form button {
    border-radius: 0px;
}

.Filter {
    display: flex;
}

.Filter .form-group {
    margin: 0px 10px 20px 0px;
    display: inline-block;
}

.Filter .form-group:last-child {
    margin: 0px 0px 20px;
}

.Filter .form-group label {
    display: block;
    font-weight: 700;
    font-size: 14px;
    color: rgb(0, 0, 0);
}

.Filter .form-group .form-control {
    height: 42px;
    line-height: 25px;
    padding: 8px 10px;
    border: 1px solid rgb(203, 202, 202);
    font-size: 12px;
    margin: 0px 10px 0px 0px;
    box-shadow: none;
    color: rgb(62, 61, 61);
    font-weight: 600;
    border-radius: 5px;
    appearance: auto;
}

.Overview {
    background: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 10px;
    margin: 0px 0px 20px;
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-color: rgb(221, 221, 221) rgb(221, 221, 221) rgb(221, 221, 221) rgb(185, 185, 185);
    border-image: initial;
    box-shadow: rgb(221, 221, 221) 0px 0px 10px;
}

.Overview h2 {
    font-size: 15px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    margin-bottom: 20px;
    min-height: 40px;
}

.Overview aside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Overview aside span {
    width: 50px;
    height: 50px;
    background-color: rgb(221, 221, 221);
    border-radius: 50px;
    text-align: center;
    line-height: 50px;
}

.Overview aside i {
    font-size: 18px;
    color: rgb(0, 0, 0);
}

.Overview aside h3 {
    font-size: 25px;
    color: rgb(0, 0, 0);
    font-weight: 800;
}

.TableList {
    overflow: auto;
    text-align: center;
}

.TableList table {
    border: 1px solid rgb(220, 220, 220);
}

.TableList table tr:nth-child(2n+2) {
    background-color: rgb(245, 245, 245);
}

.TableList table tr:hover {
    background: rgb(199, 199, 199);
}

.TableList table tr th {
    background-color: rgb(231, 230, 230);
    font-weight: 700;
    text-transform: capitalize;
    color: black;
    font-size: 14px;
    padding: 10px 13px;
    text-align: left;
}

.TableList table tr td {
    font-weight: 600;
    color: rgb(0, 0, 0);
    font-size: 13px;
    text-align: left;
    padding: 10px 13px;
}

.TableList table tr td a:hover {
    color: rgb(0, 0, 0);
}

.TableList table tr th:last-child, .TableList table tr td:last-child {
    text-align: center;
    min-width: 14px;
}

.TableList table tr td:last-child a {
    color: rgb(0, 0, 0);
    margin: 0px 5px;
    font-size: 14px;
    display: inline-block;
}

.TableList table tr td:last-child a:hover {
    color: rgb(0, 0, 0);
}

.TableList table tr td button {
    padding: 5px 10px;
    border-radius: 5px;
    width: 90px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    background: green;
    color: white;
}
.TableList table tr td button.Yellow2{
    background: #e5e50a;
    color: black;
    border: 1px solid #e5e50a;
    font-weight: 700;
}
.TableList table tr td button .Green {
    color: rgb(55, 167, 19);

}
.TableList table tr td button .Yellow {

color: rgb(254, 202, 5);}




.TableList table tr td .Actions {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

td, th {
    padding: 8px;
}

.TableList table tr td a.Green {
    color: rgb(55, 167, 19);
}

.TableList table tr td a.Red {
    color: rgb(198, 32, 32) !important;
}

.TableList table tr td a.Blue {
    color: rgb(14, 81, 141) !important;
}

.TableList table tr td a.Yellow {
    color: rgb(254, 202, 5);
}

.TableList table tr td a.Pink {
    color: rgb(253, 84, 109);
}

.TableList table tr td span.Green {
    background-color: rgb(55, 167, 19);
    color: white;
        border-radius: 5px;
        display: inline-block;
        font-size: 12px;
        padding: 5px 10px;
        text-align: center;
        width: auto;
}

.TableList table tr td span.Blue {
    background-color: rgb(14, 81, 141);
}

.TableList table tr td span.Red {
    background-color: rgb(187, 15, 15);
    color: white;
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
    text-align: center;
    width: auto;

}

.TableList table tr td span.Orange {
    background-color: rgb(223, 153, 25);
}

.TableList table tr td:last-child a {
    color: rgb(0, 0, 0);
    margin: 0px 10px 0px 0px;
    font-size: 14px;
    display: inline-block;
}

/* .TableList tr td button {
    border: 1px solid rgb(145, 140, 140);
    padding: 2px 6px;
    font-size: 12px;
    color: white !important;
    background: green;
    border-radius: 5px;
} */

.PannelOne {
    cursor: pointer;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.PannelOne h4 {
    font-weight: 600;
    font-size: 17px;
    color: rgb(0, 0, 0);
    background-color: rgb(233, 233, 233);
    padding: 15px;
    margin: -20px -20px 20px;
}

.PannelOne span {
    padding-right: 10px;
    position: absolute;
    top: 10px;
    right: 18px;
    font-size: 15px;
}

.GuestsArea {
    display: flex;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    padding: 11px;
}

.GuestsArea .GuestsLeft {width: 150px;}

.GuestsArea .GuestsLeft figure {
    margin: 0px;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
}

.GuestsArea .GuestsRight {
    width: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 0 0 15px;
}

.GuestsArea .GuestsRight aside {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.GuestsArea .GuestsRight aside a {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-align: left;
    line-height: normal;
}

.GuestsArea .GuestsRight aside button {
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: normal;
    padding: 2px 10px;
    border-radius: 5px;
    background-color: rgb(99, 161, 3);
    border: 2px solid rgb(99, 161, 3);
    font-size: 12px;
}

.GuestsArea .GuestsRight article {
    display: flex;
    align-items: baseline;
}

.GuestsArea .GuestsRight article span {
    width: 15px;
    display: inline-block;
    margin: 0px 4px 0px 0px;
}

.GuestsArea .GuestsRight article p {
    font-size: 13px;
    font-weight: 700;
    color: #8b8b8b;
}

.GuestsArea .GuestsRight label {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 0;
    text-decoration: none;
}

.GuestsArea .GuestsRight label a {
    font-size: 13px;
    color: #63a103;
    font-weight: 700;
}

.Subscriptions {
    padding: 20px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.Subscriptions aside {
}

.Subscriptions aside h2 {
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    font-weight: 700;
    margin: 0 0 7px 0;
}

.Subscriptions aside h3 {
    font-size: 20px;
    color: green;
    font-weight: 800;
    text-align: center;
    line-height: 20px;
    margin: 0 0 10px 0;
}

.Subscriptions aside p {
    font-size: 13px;
    color: #333333;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
}

.Subscriptions aside ul {
    display: flex;
    justify-content: center;
    margin: 10px 0px 0px;
}

.Subscriptions aside ul li {
    margin: 0 10px;
    font-size: 18px;
}

.Subscriptions aside ul li a {
}

label {
    color: rgb(0, 0, 0);
}

.form-group .form-control {
    box-shadow: none;
    border: 1px solid rgb(221, 221, 221);
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    padding: 6px 11px;
    height: auto;
}

.form-group textarea:focus {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-color: rgb(0, 0, 0);
    outline: 0px;
    box-shadow: none;
    font-size: 12px;
}


.CommonLinksNew {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.CommonLinks a{
    color: black;
    font-size: 18px;
    font-weight: 700;
}
.back {
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

.Subscription-cancel {
    margin: 10px 0px 20px;
    display: flex;
    justify-content: space-between;
}

.Subscription-cancel aside{

display: flex;
}
.Subscription-cancel aside a{
    border: 1px solid #dbdbdb;
    padding: 15px;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
    color: #165885;
    font-size: 14px;
    font-weight: 700;
}
.Subscription-cancel aside a .fa{

margin: 0px 0px 0px 20px;
}

.Subscription-cancel ul li {
    border: 1px solid #dbdbdb;
    padding: 15px;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
}

.Subscription-cancel ul li a {
    color: rgb(0, 0, 0);
    display: block;
    font-weight: 600;
    font-size: 15px;
    margin: 0px 15px 0px 0px;
}

.UserBox .UserHead {
    display: flex;
    margin: 0px 0px 15px;
    align-items: center;
}

.UserBox .UserHead figure {
    width: 50px;
    margin: 0px 15px 0px 0px;
    border-radius: 50%;
    border: 1px solid black;
}

.UserBox .UserHead figure img {
    border-radius: 50%;
}

.UserBox .UserHead figcaption {
    width: calc(100% - 65px);
}

.UserBox .UserHead figcaption h3 {
    font-weight: 700;
    font-size: 16px;
    margin: 0px 0px 5px;
}

.UserBox .UserBody {
    display: flex;
    justify-content: space-between;
}

.UserBox .UserBody aside {
    width: 50%;
}

.UserBox .UserBody aside p {
    font-size: 14px;
    margin: 0px 0px 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    font-family: Mulish;
}

.UserBox .UserBody aside p:last-child{ margin:0 }

.UserBox .UserBody aside p label {
    margin: 0px 15px 0px 0px;
    width: 165px;
    position: relative;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.UserBox .UserBody aside p label::after {
    content: ":";
    position: absolute;
    right: 0px;
    top: 0px;
}

.UserBox .UserBody aside p span {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: green;
}

.UserBox {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    background: #fbfbfb;
}

.UserHead h6 {
    font-size: 14px;
    margin: 0 0 6px 0;
    color: #838181;
    font-weight: 600;
}

.cust-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px 0px 5px;
    font-size: 15px;
    font-weight: 600;
    color: black;
    align-items: baseline;
}

.left-details {
    display: flex;
}

.left-details img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.right-details p {font-size: 16px;color: #000;margin: 0 0 4px 0;font-weight: 700;}

.back {
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

.UserHead h6 span {
    width: 15px;
    display: inline-block;
    margin: 0px 5px 0px 0px;
}

.UserBody figure {
    width: 180px;
    border: 5px solid #fff;
    border-radius: 5px;
    margin: 0px 15px 0px 0px;
    box-shadow: 0px 0px 10px #b7b7b7;
}

.UserBody article {
}

.UserBody article a {
    color: green;
    text-decoration: underline;
    line-height: 20px;
    font-size: 15px;
    font-weight: 800;
}

.UserBody article p {
    font-size: 12px;
    font-weight: 600;
    color: green;
}

.UserBody article p a {
    color: #064d89;
    margin: 0px 0px 0px 0px;
}

.UserBody article p span {
}

.UserBody article p span img {
    margin: 0px 4px 4px 0px;
}

.partner-details {
}

.partner-details ul {
    display: flex;
    margin: 20px 0px;
    padding: 0;
    flex-wrap: wrap;
}

.partner-details ul li {
 
background-color: #ddd;
 
border: 1px solid #ddd;
 
border-radius: 10px;
 
width: calc(100% / 5 - 10px);
 
margin: 0 5px 15px;
}

.partner-details ul li a{
    border-radius: 9px;
    border: 1px solid rgb(225 224 224);
    background-color: rgb(255, 255, 255);
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-details ul li .partner-services {
    padding: 15px;
    text-align: center;
}
.partner-details ul li .partner-services p{
    color: black;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 10px;
}

.partner-details ul li .partner-services figure {
    width: 60px;
    margin: auto;
}

.partner-services figure {
}

.partner-services figcaption {
    padding: 8px;
}

.partner-services figcaption h3 {
    font-weight: 600;
    color: rgb(51, 51, 51);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
}

.total-order h3 {
    font-size: 16px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 5px 0px;
}

.total-order+.total-order{ margin-top:15px }

.order-address {
    border: 1px solid #d7d7d7;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 10px;
    background: #f5f5f5;
}

.order-address+.order-address { margin-top:15px }

.order-address-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.order-address-footer {
}

.order-address-body a {
    text-decoration: underline;
    font-weight: 700;
    /* color: #000; */
    font-size: 14px;
}

.order-address p {
    color: #333333;
    line-height: 21px;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 7px 0;
}

.order-address h4 {
    font-size: 15px;
    font-weight: 700;
}

.order-address h4 span {
    color: green;
}

.order-address-footer .Cancellation {
    border: 1px solid rgb(221, 221, 221);
    color: black;
    text-decoration: none;
    padding: 4px 15px;
    display: inline-block;
    margin-top: 10px;
    border-radius: 5px;
    background: rgb(221, 221, 221);
    font-size: 12px;
    font-weight: 500;
}

.UserHead h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 7px 0;
}

.panel-body ul li .panel-item aside h2 {
    color: rgb(85, 85, 85);
    font-size: 15px !important;
    line-height: 18px !important;
}

.panel-body ul li .panel-item aside p {
    color: green !important;
    font-weight: 600 !important;
}

.panel-body ul li .panel-item aside p span {
    margin: 0px 5px 0px 0px;
}

.panel-body ul li .panel-item a {
    position: absolute;
    top: 0px;
    right: 0px;
    color: black;
    font-size: 16px;
}

.subscription {
    background: rgb(249, 249, 249);
    border-radius: 10px;
    border: 1px solid rgb(221, 221, 221);
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
}

.subscription p {
    line-height: 20px;
}

.comn-item img {
    width: 50px;
    border-radius: 50%;
    margin-right: 18px;
}

.comn-item {
    display: flex;
    align-items: center;
}

.comn-item h2 {
    font-size: 15px;
}

.comn-item span {
    color: rgb(22, 88, 133);
    margin: 0px 0px 0px 12px;
}

.order-address-body .UserHead {
    display: flex;
    align-items: center;
}

.order-address-body .UserHead figure {
    width: 180px;
    border-radius: 10px;
    margin: 0px 15px 0px 0px;
    overflow: hidden;
    border: 5px solid #fff;
    box-shadow: 0px 0px 12px #c5c5c5;
}

.order-address-footer h3 {
    color: green;
    font-size: 14px;
}

.pagination {
    display: flex !important;
    justify-content: flex-end;
    margin-top: 20px;
}

.pagination a {
    color: black;
    float: left;
    padding: 2px 6px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.pagination a.active {
    background-color: rgb(22, 88, 133);
    color: white;
    border-radius: 5px;
}

.earning {
    text-align: right;
    margin: 8px 0px;
}
.earning p {
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
}

.earning p span {
    color: green;
}

.earning a {
    font-size: 18px;
    font-weight: 700;
    color: #3bd10a;
    text-decoration: underline;
    font-style: italic;
}

.earning2 p {
    line-height: 22px;
}

.earning2 a {
    text-decoration: none;
}
.saveBtn {
    padding: 0px 0px 25px;
    margin: 40px 0px 0px;
    text-align: center;
}

.ck-content {
    min-height: 200px;
}

/*.ModalBox .modal-dialog {
    max-width: 450px;
    margin: 50px auto;
}

.ModalBox .modal-dialog .Category {
    padding: 10px;
}

.ModalBox .modal-dialog .CloseModal {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 26px;
    height: 26px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(0, 0, 0, 0.5);
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 15px;
    border-radius: 50%;
}

.ModalBox .modal-dialog .Decline h3 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 22px;
    margin: 0px 0px 5px;
    text-align: left;
}

.ModalBox .modal-dialog .Decline p {
    font-size: 16px;
    font-family: Roboto;
    color: rgb(119, 119, 119);
    text-align: left;
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0px 0px;
    text-align: right;
}

.ModalBox .modal-dialog .Decline h4 a {
    text-transform: capitalize;
    margin: 0px 0px 0px 30px;
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    transition: all 0.5s ease-in-out 0s;
}

.ModalBox .modal-dialog .Category h3 {
    text-align: center;
    font-weight: 700;
    margin: 0px 0px 20px;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding: 0px 0px 15px;
    font-size: 18px;
}
*/
.notification-btn {
    display: flex;
    justify-content: space-between;
}

.notification-btn .active {
    background: rgb(0, 0, 0) !important;
}

.notification-btn .active:hover {
    background: rgb(22, 88, 133);
}

.CommonLinks ul li {
    margin: 0px 10px 0px 0px;
    display: inline-block;
}
.CommonLinks ul li:last-child{
    margin: 0px 0px 0px 2px;
}
.CommonLinks ul li a {
    background-color: rgb(208, 206, 206);
    border-radius: 5px;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    display: block;
    border: none !important;
}

.CommonLinks ul li.active a {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(22, 88, 133), rgb(22, 88, 133));
}

.CommonTabs .nav-tabs li {
    margin: 0px 15px 0px 0px;
}

.CommonTabs {
    margin-bottom: 20px;
}

.CommonTabs .nav-tabs {
    border: none;
}

.CommonTabs .nav-tabs li {
    margin: 0px 15px 0px 0px;
}

.CommonTabs .nav-tabs li a {
    background-color: rgb(208, 206, 206);
    border-radius: 5px;
    padding: 11px 35px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    border: none !important;
}

.CommonTabs .nav-tabs li a.active {
    background-image: linear-gradient(45deg, rgb(249, 140, 28), rgb(251, 185, 20));
    color: rgb(255, 255, 255);
}

.CommonTabs .nav-tabs li a.active {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(22, 88, 133), rgb(22, 88, 133));
}

.CommonTabs .nav-tabs li a {
    border-radius: 5px;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    border: none !important;
}

.SearchBox {
    margin: 0px;
    float: right;
    display: flex;
}

.SearchBox form {
    margin: 0px 10px 0px 0px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.SearchBox form input {
    margin: 0px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: none;
    outline: 0px;
    padding: 0px 13px;
    height: 43px;
    min-width: 190px;
    border-radius: 5px 0px 0px 5px;
    font-weight: 600;
}

.SearchBox form button {
    border-radius: 0px;
}


.MenuList ul li {
    background: rgb(245, 245, 245);
    padding: 20px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(209, 209, 209);
    justify-content: space-between;
    margin: 0px 0px 12px;
}

.MenuList ul li .MenuLeft {
    display: flex;
    align-items: center;
}

.MenuList ul li .MenuLeft figure {
    margin: 0px;
    width: 70px;
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
}

.MenuList ul li .MenuLeft figcaption {
    padding: 0px 0px 0px 15px;
}

.MenuList ul li .MenuLeft figcaption h3 {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0px 0px 6px;
}

.MenuList ul li .MenuLeft figcaption h4 {
    font-size: 12px;
    font-weight: 700;
    margin: 0px 0px 5px;
}
/* .MenuList ul li .MenuMiddle figcaption h4{
    font-size: 12px;
    font-weight: 700;
    margin: 0px 0px 5px;
} */

.MenuList ul li .MenuLeft figcaption h4 span {
    margin: 0px 60px 0px 0px;
}
.MenuList ul li .MenuLeft figcaption h5{
    font-size: 14px;
    font-weight: 700;
}
.MenuList ul li .MenuLeft figcaption p {
    font-size: 14px;
    font-weight: 800;
    margin: 5px 0px;
}
.MenuList ul li .MenuLeft figcaption p span{margin: 0px 8px 0px 0px;display: inline;}

.MenuList ul li .MenuRight {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 30%;
}

.MenuList ul li .MenuRight h5 {
    font-weight: 600;
    margin: 0px 16px 0px 0px;
    font-size: 16px;
}

.MenuList ul li .MenuRight h5 span {
    display: block;
    font-size: 12px;
    color: green;
}
.MenuList a i {
    font-size: 20px;
}

.orderReq .order-head {
    display: flex;
    justify-content: space-between;
}

.orderReq .order-head figcaption {
}

.orderReq .order-head figcaption h4 {
    font-size: 15px;
    font-weight: 600;
    color: green;
}

.orderReq .order-head figcaption p {
    font-size: 14px;
    margin: 0 0 6px 0;
}

.orderReq .order-head aside {
}

/*.ModalBox .modal-dialog .Decline {
    margin: 0px;
    padding: 10px;
}

.ModalBox .modal-dialog .Decline h3 {
    font-weight: 700;
    font-size: 20px;
    margin: 0px 0px 15px;
    color: black;
}

.ModalBox .modal-dialog .Decline p {
    font-size: 15px;
    color: rgb(66, 64, 64);
    text-align: left;
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0px 0px;
    text-align: right;
}*/

.ModalBox .modal-dialog .Decline figure {
    width: 100px;
    margin: auto;
    padding: 20px 0px;
}

.ModalBox .modal-dialog .Decline .Button {
    width: 100%;
}

.MenuRight .silder-right {
    display: flex;
    border: 2px solid rgb(22, 88, 133);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
}

.MenuRight .silder-right button {
    width: 30px;
    height: 28px;
    background-color: rgb(22, 88, 133);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 20px;
    line-height: 22px;
    outline: 0px;
}

.MenuRight .silder-right input {
    padding: 0px;
    border: none;
    width: 40px;
    height: 27px;
    text-align: center;
    outline: 0px;
}

.order-status-body {
    text-align: center;
    padding: 0px 250px;
}

.order-status-body .order-status {
}

.order-status-body .order-status figure {
    width: 70px;
    margin: auto;
}

.order-status-body .order-status figcaption {
    color: green;
    margin: 20px 0px;
    font-weight: 600;
    font-size: 16px;
}

.order-status-body .qr-code {
}

.order-status-body .qr-code figure {
    width: 140px;
    margin: auto;
    padding: 40px 0px;
}

.order-status-body .qr-code figcaption {
}

.order-status-body .order-status figcaption h3 {
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
}

.qr-code-otp {
}

.qr-code-otp form {
    margin: auto;
}

.qr-code-otp form .form-group {
    margin: 0px 0px 20px;
    position: relative;
}

.qr-code-otp form .form-group .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0px 0px 10px;
}

.qr-code-otp form .form-group .OTPBox .form-control {
    padding: 0px;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 30px;
    border: 1px solid #b7b7b7;
    margin: 0 10px;
}

.message {
    display: flex;
    justify-content: space-between;
}

.message aside {
    display: flex;
    align-items: center;
}

.message aside figure {
    width: 50px;
    border: 1px solid gray;
    border-radius: 50%;
    margin: 0px 12px 0px 0px;
    overflow: hidden;
}

.message aside figcaption {
    font-size: 15px;
}

.message article {
    display: flex;
    align-items: center;
}

.message article figure {
    margin: 0px;
}

.message article figcaption {
    margin: 0px 0px 0px 12px;
    font-size: 14px;
}

.message article figcaption a {
    color: green;
    font-weight: 700;
}

.Decline .MenuList ul li {
    padding: 8px;
}

.Decline .MenuList ul li .MenuLeft figure {
    width: 50px;
    height: 50px;
    padding: 0px;
}

.Decline .MenuList ul li .MenuLeft figcaption h3 {
    font-size: 14px;
    margin: 0px;
}

.Decline .MenuList ul li .MenuLeft figcaption p {
    font-size: 12px;
}

.Decline .MenuList ul li .MenuLeft figcaption h4 {
    text-align: left;
    margin: 0px;
}

.Decline .MenuList ul li .MenuRight figcaption h5 {
    font-size: 12px;
}

.Book {
    border: 1px solid gray;
    padding: 3px 10px;
    border-radius: 5px;
    color: white;
    background: black;
}

.Book:hover {
    background-color: rgb(14, 81, 141);
    color: white;
}

.AmenitiesArea ul li .AmenitiesBox p {
    font-size: 12px;
    font-weight: 600;
    line-height: 21px;
}

.AmenitiesArea2 ul li {
    width: calc(50%) !important;
}

.AmenitiesArea .AmenitiesBox figcaption a.add {
    padding: 3px 20px;
    font-size: 12px;
    margin-top: 10px;
}

.guest-menuList .MenuRight {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.guest-menuList .MenuRight p a {
    text-decoration: underline;
    font-weight: 700;
}

.TitleBox ul {
    display: flex;
}

.TitleBox ul li {
    margin: 0px 0px 0px 10px;
}

span.icon {
    width: 80px;
    display: inline-block;
}
.total-bill{margin: 12px 0px 0px 0px;}
.total-bill h3{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.total-bill p{
    color: black;
    font-size: 14px;
    line-height: 25px;
}
.HotelLocation2{
    display: flex;justify-content: space-between;align-items: center;
}


.CheckBox {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    font-weight: 600 !important;
    color: #2d2d2d;
    margin: 0 !important;
    min-height: 20px;
}
.CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.CheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 24px;
    background-color: transparent;
    border: 1px solid #165885;
    border-radius: 2px;
}
.SupportDetails {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
}
.SupportDetails ul li {
    margin-bottom: 20px;
    display: flex;
}
.SupportDetails ul li figure {
    color: #4d5052;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    font-size: .875rem;
    user-select: none;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.SupportDetails ul li figcaption {
    display: inline-block;
    width: calc(100% - 50px);
    max-width: 850px;
}
.SupportDetails ul li figcaption h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption p {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption span.time {
    display: block;
    font-weight: 700;
    color: #565656;
    font-size: 12px;
}
.SupportDetails ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
}
.SupportDetails ul li.OtherMessage {
    flex-direction: row-reverse;
}
.SupportDetails ul li figure {
    color: #4d5052;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    font-size: .875rem;
    user-select: none;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.SupportDetails ul li.OtherMessage figure {
    margin: 0 0 0 10px;
}
.SupportDetails ul li figcaption {
    display: inline-block;
    width: calc(100% - 50px);
    max-width: 850px;
}
.SupportDetails ul li.OtherMessage figcaption {
    text-align: right;
}
.SupportDetails ul li figcaption h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption p {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption span.time {
    display: block;
    font-weight: 700;
    color: #565656;
    font-size: 12px;
}
.MessageReply {
    border: 1px solid #ddd;
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
}
.MessageReply .form-control {
    border: none;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    height: auto;
}
.MessageReply button {
    border: none;
    background: #165885;
    font-size: 15px;
    padding: 8px 30px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
}



.NotificationsBox ul li+li{margin:15px 0 0 0}
.NotificationsBox ul li aside{background:#f9f9f9;padding:10px;border:1px solid #ddd;border-radius:5px}
.NotificationsBox ul li aside p{font-size:14px;font-weight:500;line-height:27px;margin:0 0 5px 0}
.NotificationsBox ul li aside h6{text-align:right;font-size:15px;font-weight:700;color:#000}

.Subscription-cancel {
    margin: 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Subscription-cancel aside a {
    border: 1px solid #dbdbdb;
    background: #fff;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
}

.back-btn {
    text-align: end;
    margin-top: -15px;
}
.back-btn a {
    font-size: 18px;
    display: inline-block;
    color: #225476;
    font-weight: 700;
}
.order-Date {
    text-align: right;
    font-size: 12px;
    font-weight: 700;
}
.reply{
    text-align: right;
    text-decoration: underline;
    font-weight: 700;
}
.total-bill h4 {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin: 15px 0px 5px 0px;
}
.ModalBox .modal-dialog .Category .form-group .Icon{
    position: absolute;
    top: 55%;
    right: 18px;
}

.MessageReply {
    border: 1px solid #ddd;
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
    align-items: flex-end;
    justify-content: flex-end;
}
.MessageReply .form-control {
    border: none;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    height: auto;
}
.MessageReply button {
    border: none;
    font-size: 15px;
    padding: 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    background: white;
    position: relative;
    cursor: pointer;
}
.MessageReply button img{
    width: 20px;
}
.MessageReply input {
    position: absolute;
    top: 6px;
    left: 1px;
    opacity: 0;
    width: 100%;
}
.MessageReply .msg-send{
    position: absolute;
}

.earning3{
    display: flex;
    justify-content: space-between;
}
.ModalBox .modal-dialog .Decline .qr-pera {
    text-align: center;
}
.Deluxe {
    font-size: 15px;
    color: green;
    font-weight: 700;
    margin-bottom: 8px;
}

.UserFooter{
    margin-top: 40px;
}
.UserFooter article{
    display: flex;
}
.UserFooter article figure{
    width: 180px;
    border: 1px solid #ddd;
    padding: 2px;
    margin: 0px 16px 0px 0px;
}
.UserFooter article figcaption{}
.UserFooter article figcaption p{
    font-size: 12px;
    font-weight: 700;
}
.UserFooter article figcaption .HotelLocation{
    text-align: left;
    margin: 0 0 5px 0;
}
.UserFooter {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  padding-left: 40px;
}




.UploadBox{ display: flex; }
.UploadBox .Upload{border:2px dashed #b9b9b9;padding: 37px 0;border-radius: 8px;position:relative;display: inline-block;width: 160px;height: 135px;text-align: center;background-color: #fff;}
.UploadBox .Upload i{font-size:24px}
.UploadBox .Upload span{font-size: 14px;font-weight:600;color:#000;text-transform:capitalize;display: block;}
.UploadBox .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;opacity:0}

.UploadBox .UploadIcon {
    margin: 0 0 0 20px;
    border: 2px dashed #9d9d9d;
    border-radius: 5px;
    padding: 10px;
    width: 135px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UploadBox .UploadIcon img{ width: 85px; }

.Icon{
    position: absolute;
    top: 45%;
    right: 5%;
}

/* .cross{
    top: 45%;
    right: 5%;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.ModalBox .modal-dialog .Category .form-group .RoomBox {
    position: relative;
    /* border: 1px solid #c3c3c3; */
    display: flex;
    margin: 0 -5px;
}

.ModalBox .modal-dialog .Category .form-group .RoomBox span {
    background-color: #ddd;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border-right: 1px solid #c3c3c3;
}


.ModalBox .modal-dialog .Category .form-group .RoomBox .form-control {
    /* border: none; */
    margin: 0 5px;
}
.ModalBox .modal-dialog .Category .form-group .CheckBox {
    display: inline-block;
    min-width: 130px;
    min-height: auto;
}


.CategoryAdd{
    display: flex;
}

.CategoryAdd button {
    opacity: 1 !important;
    background: #d5d1d1;
    font-size: 14px;
    padding: 0 30px;
    font-weight: 600;
    color: #000;
    margin: 0 0 0 10px;
}


.ModalBox .modal-dialog .Category .form-group .___STimePicker_1gaoj_gg_{
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    color: #000;
    line-height: 22px;
    padding: 10px 10px;
    height: auto;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.ModalBox .modal-dialog .Category .form-group .TimePicker {
    padding: 0 0 0 15px;
}

.ModalBox .modal-dialog .Category .form-group .TimePicker span {
    font-weight: 600;
    color: #000;
}




.ButtonFade{border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color:#fff;text-transform:capitalize;border-radius: 5px;outline: 0 !important;box-shadow: none;background: #a8a6a0;display: inline-block;}
.text-guest{color: green;}
.text-guest-unassigned{color: gray;}

.WifiArea .WifiBox article p span input {
    background: transparent;
    border: none;
    display: inline-block;
    outline: none;
    width: 150px;}

    .FacilityBox ul li {
        align-items: center;
        background-color: #fff!important;
        border-radius: 8px;
        color: #7c8493;
        display: flex;
        font-weight: 500;
        justify-content: space-between;
        margin-bottom: 20px;
        overflow: hidden;
        padding: 16px 15px;
    }



.MainHeading {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.FilterArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MenuList ul li .MenuLeft figcaption.food-bav-content{
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    overflow: hidden;
    position: relative;
    background: white;
}
.food-bav2{
    position: absolute;
    top: 5px;
    right: 8px;
}
.food-bav2 .fa{font-size: 15px;}

.discount-package h2{
    font-size: 16px;
    color: #050558;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
}

.FilterArea .form-group {
    margin: 0 10px 0px 0;
    display: inline-block;
}
.FilterArea .form-group label {
    display: block;
    font-weight: 800;
    font-size: 13px;
    color: #000;
}
.FilterArea .form-group .form-control {
    height: 42px;
    line-height: 25px;
    padding: 8px 10px;
    border: 1px solid #cbcaca;
    font-size: 13px;
    margin: 0 10px 0 0;
    box-shadow: none;
    color: #3e3d3d;
    font-weight: 600;
    border-radius: 5px;
}

.approve-dis-btn{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.avtar-img {
    border: 1px solid #fff;
    box-shadow: 0 0 10px #b7b7b7;
    display: inline-block;
    height: 50px;
    margin-top: 30px;
    width: 74px;}

    

.AddOrder {
    background: #165885;
    border: none;
    border-radius: 5px;
    color: #fff;
    outline: 0;
    padding: 7px 20px;
}

.MenuList ul li .MenuLeft figcaption{
    text-align: justify;
    width: 380px;
    padding: 10px 20px;
}

.saveBtn button.TitleLink {
    background-image: linear-gradient(45deg,#050606,#165885);
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
}
.OrderSatus {
    background-color: #fff;
    padding: 20px;
    border: 3px dashed #ddd;
    border-radius: 10px;
    width: 500px;
    text-align: center;
    margin: auto;
}

.OrderSatus figure {
    width: 140px;
    margin: 0 auto 0px;
}

.OrderSatus figcaption {
    text-align: center;
}

.OrderSatus figcaption h3 {
    color: #009045;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.OrderSatus figcaption h4 {
    color: #464646;
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.OrderSatus figcaption h5 {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.OrderSatus table {
    border: 1px solid #ddd;
    margin: 15px 0 15px 0;
}

.OrderSatus table tr th {
    background: #d9d9d9;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #acacac;
    width: 33%;
    text-align: left;
    color: #000;
    font-weight: 700;
    padding: 8px 20px;
}

.OrderSatus table tr th:last-child{ border-right: none; }

.OrderSatus table tr td {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    color: #515151;
    font-weight: 600;
    text-align: left;
    padding: 8px 20px;
}


.OrderSatus table tr:nth-child(even) {
    background: #f1f1f1;
}

.OrderSatus .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
}

.OrderSatus .OTPBox .form-control {
    width: 45px;
    height: 45px;
    text-align: center;
    margin: 0 5px;
    color: #000;
    font-weight: 700;
}

.OrderSatus p {
    text-align: center;
    font-weight: 600;
    color: #5c5c5c;
    margin-bottom: 10px;
}


/* .NewTitleBox a{background-color:#165885;color:#fff !important;font-size:14px;font-weight:600;padding:10px 20px;border-radius:5px;display: inline-block;margin: 0px 0px 0px 5px;}
.NewTitleBox a:hover{background-color:#04bad2} */

.NewTitleBox {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
}



.NewTitleBox a {
    color:#165885;
    margin: 0px 10px 0px 0px;
}

.NewTitleBox .arrow-left {
    align-items: center;
    display: flex;
    justify-content: flex-start;

}

.___SDropdownPopper_1ogfh_gg_{
    display: none;
}

.CommonForm .form-group .react-tel-input .form-control {    
    width: 100%;
    padding: 10px 10px 10px 55px;


}


ul.dashboard-guest{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
ul.dashboard-guest li {
    width: 45%;
    margin: 0px 0px 10px 0px;
}


.GuestAccordian .card .Approve {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    padding: 8px 12px;
    border-radius: 5px;
    margin: 0 0 0 10px;
    border: none;
    background: #3fb50f;
    position: absolute;
    right: 0;
    z-index: 9;}

    .ManualFalse {
        position: absolute;
        right: 0;
        background-color: #165885;
        border: none;
        padding: 8px 12px;
        color: #fff;
        border-radius: 5px;
        font-weight: 600;
        font-size: 13px;
        z-index: 99;
    }

    .CommonForm .form-group .UploadBox figure {
        position: absolute;
        left: 192px;
        width: 123px !important;
        height: 123px;
        border-radius: 10px;
    }
    

    .addOccupant {
        text-align: right;
        font-size: 15px;
        font-weight: 800;
    }


    .CloseCard {
        position: absolute;
        top: -13px;
        right: -13px;
        width: 26px;
        height: 26px;
        background-color: #fff;
        border: 2px solid rgba(0,0,0,.5);
        text-align: center;
        color: #000;
        font-size: 15px;
        border-radius: 50%;
    }
    
    .manualCheckin {
        background-color: #ebeef1;
        padding: 25px;
        border: 1px solid #dcdcdc;
        border-radius: 10px;
        margin: 0 0 20px 0;
        position: relative;
    }


    .NoDataFound{
        min-height: 14vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .NoDataFound h2{
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }


    .approveRequestBtn{
        display: flex;
        justify-content: flex-end;
    }

    .approveRequestBtn{
        display: flex;
        justify-content: flex-end;
    }
    
    .Duration{
        border: 1px solid #c3c3c3;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 0 5px 0;
    }
    
    
    .Duration span{}
    
    .Duration select{
        border: none;
        outline: 0;
        padding: 10px 15px;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        cursor: pointer;
    }
    
    
    .emp-type-radio{display: flex;}

.emp-type-radio .form-group label{margin: 0px 40px 0px 0px !important;}
    
.employe-type label{
    font-weight: 700 !important;
    font-size: 14px;}


.settings-area{display: flex;
    justify-content: space-between;}
.Small-Wrapper2{width: 65%;}
.WifiBox{
    width: 32%;
    background: white;
    border-radius: 10px;
    padding: 15px;


}

.WifiBox aside .switch {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0px;
    padding: 2px 0px 3px 50px;
    font-weight: 400;
    font-size: 12px;
    color: green;
}

.ModalBox .modal-dialog .Category .form-group .react-tel-input {}
.ModalBox .modal-dialog .Category .form-group .react-tel-input .form-control {
    width: 100%;
    padding: 10px 10px 10px 55px;
}


.Radio .checkmark-new:after{
    width: 6px;
    border-radius: 0px;
    background: none;
}

.LoaderBox {
    margin-top: 120px;
    text-align: center;
}


.review-rating aside.reply-right{
    margin: 20px 0px 0px 50px;
}
.review-rating aside.reply-right .reply-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.review-rating p {
    line-height: 20px;
}
.review-rating .peraDate {
    text-align: right;
    font-size: 12px;
    color: black;
}
.review-rating a{display: block;
    text-align: right;
    text-decoration: underline;
}


.WrapperArea {
    margin-left: 235px;
    flex: 1 1 auto;
    width: calc(100% - 235px);
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    transition: all 0.5s ease-in-out 0s;
}


.RiderBox:last-child {
    margin: 0px;
}

.RiderBox {
    background-color: rgb(239, 239, 239);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgb(221, 221, 221);
    margin: 20px 0px 0px 0px !important;
}

.RiderBox .RiderHead {
    display: flex;
    margin: 0px 0px 20px;
    justify-content: space-between;
    align-items: center;
}



.RiderBox .RiderHead figure {
    width: 50px;
    height: 50px;
    margin: 0px 20px 0px 0px;
    border-radius: 50%;
    border: 1px solid black;
    text-align: center;
}



.RiderBox .RiderHead figcaption {
    width: calc(100% - 65px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RiderBox .RiderBody {
    display: flex;
    justify-content: space-between;
}

.RiderBox .RiderBody aside p label {
    margin: 0px 15px 0px 0px;
    width: 255px;
    position: relative;
    color: rgb(0, 0, 0);
    font-weight: 700;
}


.amenities ul li {
    display: inline-block;
    width: 25%;
    margin: 0px 0px 15px 0px;
}

.CommonForm .form-group.HotelNumber .Verify {
    top: 45px;
}

.CommonForm .form-group .Verify {
    font-weight: 800;
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
}


.ModalBox .modal-dialog .Category .form-group .OTPBox {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 10px;
}
.ModalBox .modal-dialog .Category .form-group .OTPBox .form-control {
    padding: 0px;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 30px;
    border-color: gray;
}

.subsTitleBox{
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
}
.subsTitleButtons{
    border: none;
    padding: 10px 30px;
    text-align: center;
    transition: all 0.5s ease-in-out 0s;
    position: relative;
    font-size: 14px;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: none;
    display: inline-block;
    background-color: rgb(22, 88, 133) !important;
    outline: 0px !important;
    margin-left: 10px;
}
.subsTitleButtonContainer {
    display: flex;
    float: right;
    font-weight: bold;
    padding-right: 30px;
    font-size: larger;
    gap: 10px;
}

.subscriptionActive {
    background-color: green !important;
}
.subscriptionInactive{
    background-color: red !important;
}


.hotelCustomSubFixedDetailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.hotelCustomSubInputDetailsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.AddRegularSubscription {
    margin-left: 10px;
    margin-right: 10px;
}

.subscriptionPlans {
    display: flex;
    justify-content: space-evenly;
    gap: 80px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 55%;
    font-weight: 400;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.subscriptionplanBox {
    position: relative;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 35px;
    padding: 50px 50px 50px 50px;
    width: 300px;
    height: 400px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}
.subscriptionplanBox:hover {
    transform: scale(1.05);
}

.subscriptionPlanSelected {
    border: 2px solid;
    border-color: #e1b444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.subscriptionPlanBoxheading {
    font-size: x-large;
    font-weight: bold;
    position: relative;
    margin-left: 5px;
}

.subscriptionPlanBoxSubheading {
    color: gray;
    margin-left: 5px;
    font-size: larger;
    margin-bottom: 15px;
}

.subscriptionPlanBoxDescription {
    background: linear-gradient(90deg, #062a44 0%, #0f69aa 50%);
    width: 130px;
    border-radius: 40px;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
}

.strikedtxt {
    text-decoration: line-through;
    color: #000000;
    font-weight: 700;
    font-size: larger;
    position: relative;
}

.subscriptionPricetxt {
    color: #000000;
    font-weight: 700;
    font-size: large;
    margin-top: 2px;
    position: relative;
}

.subscriptionPlanAbout {
    color: #898989;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 96px;
}
  
.subscriptionPlanAbout strong {
    color: #e1b444; /* Change color */
    font-weight: bold; /* Adjust font weight */
}

.subscriptionUpdateButtonContainer {
    width: 100%;
    align-items: center;
    bottom: 20px;
    right: auto;
    left: auto;
    position: absolute;
}

.subscriptionPlanUpdateButton {
    background-color: #e1b444c4;
    color: #000000;
    border: none;
    border-radius: 18px;
    height: 55px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 60%;
    transition: background-color 0.3s, transform 0.2s;
    position: relative;
}
.subscriptionPlanUpdateButton:hover {
    background-color: #e1b444; /* Darker shade on hover */
    transform: scale(1.05);
}

.subscriptionPlanUpdateButtonFadeOut {
    opacity: 0.5;
}

.subscriptionBillingModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscriptionUpdateModalBody {
    position: relative;
    margin-top: 200px;
    background-color: white;
}

.subscriptionUpdateModalContent {
    background: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    min-height: 290px;
    max-width: 500px;
    position: relative;
}

.subscriptionUpdateModalheading {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.updateSubscriptionButtonContainer {
    display: flex;
    float: right;
    padding: 20px;
}


.arrow-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.NewTitleBox .arrow-left a{
 background: none !important;
 color: #165885 !important;
}

.subscriptionModelheading {
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10%;
}

.subscriptionModelpara {
    font-size: medium;
    color: #898989;
    margin-left: 10%;
}

.subscriptionPlans {
    display: flex;
    justify-content: space-evenly;
    gap: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 55%;
    width: 100%;
    font-weight: 400;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.subscriptionplanBox {
    position: relative;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 35px;
    padding: 50px 50px 50px 50px;
    width: 300px;
    height: 400px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}
.subscriptionplanBox:hover {
    transform: scale(1.05);
}

.subscriptionPlanSelected {
    border: 2px solid;
    border-color: #e1b444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.subscriptionPlanBoxheading {
    font-size: x-large;
    font-weight: bold;
    position: relative;
    margin-left: 5px;
}

.subscriptionPlanBoxSubheading {
    color: gray;
    margin-left: 5px;
    font-size: larger;
    margin-bottom: 15px;
}

.subscriptionPlanBoxDescription {
    background: linear-gradient(90deg, #062a44 0%, #0f69aa 50%);
    width: 130px;
    border-radius: 40px;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
}

.strikedtxt {
    text-decoration: line-through;
    color: #000000;
    font-weight: 700;
    font-size: larger;
    position: relative;
}

.subscriptionPricetxt {
    color: #000000;
    font-weight: 700;
    font-size: large;
    margin-top: 2px;
    position: relative;
}

.subscriptionPlanAbout {
    color: #898989;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 96px;
}
  
.subscriptionPlanAbout strong {
    color: #e1b444; /* Change color */
    font-weight: bold; /* Adjust font weight */
}

.subscriptionChooseButtonContainer {
    width: 100%;
    align-items: center;
    bottom: 20px;
    right: auto;
    left: auto;
    position: absolute;
}

.subscriptionPlanChooseButton {
    background-color: #e1b444c4;
    color: #000000;
    border: none;
    border-radius: 18px;
    height: 55px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 60%;
    transition: background-color 0.3s, transform 0.2s;
    position: relative;
}
.subscriptionPlanChooseButton:hover {
    background-color: #e1b444; /* Darker shade on hover */
    transform: scale(1.05);
}

.subscriptionPlanChooseButtonFadeOut {
    opacity: 0.5;
}

.subscriptionModelDisclaimer {
    color: #898989;
    margin-left: 130px;
    margin-right: 120px;
    margin-top: 10px;
}

.subscriptionCouponApplyContainer {
    display: flex;
    align-items: center;
    margin: 20px;
    margin-left: 10%;
    max-width: 600px;
    width: auto;
}

.subscriptionCouponInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;
    flex: 0.3;
    text-align: center;
}

.subscriptionCouponRemoveButton{
  padding: 10px 20px;
  border: none;
  background-color: #d9d9d9;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  width: 130px;
}

.subscriptionCouponApplyButton {
    padding: 10px 20px;
    border: none;
    background-color: #e1b444;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    width: 130px;
}

.subscriptionContinueBtn {
    position: absolute;
    right: 10%;
    text-decoration: none;
    background-color: #e1b444;
    padding: 10px 20px;
    border-radius: 15px;
    margin-left: 10px;
    color: #ffffff;
    width: 175px;
    text-align: center;
    border: 0;
}

.subscriptionBillingModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscriptionBillingModalContent {
    background: white;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
    min-height: 290px;
    max-width: 500px;
    position: relative;
    margin-top: 10px;
}

.subscriptionBillingTitle {
    text-align: center;
    font-weight: bold;
}

.suscriptionBillingPlanDetailsBox {
    border: 1px solid #898989;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingPlanDetails {
    color: #000000;
    float: left;
    margin-left: 20px;
    font-size: large;
}

.subscriptionBillingPlanValues{
    color: #000000;
    float: right;
    margin-right: 50px;
    font-size: large;
}
  
.subscriptionBillingPlanValuesStriked{
    text-decoration: line-through;
    float:left;
    margin-left: 12%;
    font-size: large;
    color: #000000;
}

.subscriptionCouponApplied {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingSubtotalprice {
    color: #000000;
    font-size: large;
    margin-right: 30px;
    float: right;
}

.subscriptionBillingSubtotalBox {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingtotal {
    font-weight: bold;
    margin-bottom: 50px;
}

.subscriptionBillingCancelButton {
    border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color: black;text-transform:capitalize;border-radius: 10px;outline: 0 !important;box-shadow: none;background: #d9d9d9;;display: flex; float: right; margin-left: 10px;
}

.subscriptionBillingBuyButton {
    border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color:#fff;text-transform:capitalize;border-radius: 10px;outline: 0 !important;box-shadow: none;background: #dfa92d;display: flex; float: right;
}

.subscriptionPlanTermsandConditionlinksContainer {
    margin-left: 10%;
    display: flex;
    flex-direction: row;
}
.subscriptionPlanTermsandConditionlinks {
    color: #000000;
    padding: 10px;
    text-decoration: none;
}

.SubscriptionGSTBox {
    padding-top: 20px;
    padding-right: 50px;
    display: flex;
    justify-content: right;
}

.SplitPercentageDisplayBox {
    margin-left:4%;
    align-items: center;
    text-align: center;
    padding: 20px;
}
.SplitPercentageDisplayBoxInDashboard {
    margin-left:20%;
    align-items: center;
    text-align: center;
    padding: 20px;
}