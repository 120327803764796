
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

body {margin: 0;padding: 0;font-family: 'Mulish', sans-serif !important;font-size: 13px;color: #3a3a3a;font-weight: 400;}

.NewTitleBox{display:flex;align-items:center;justify-content:space-between;margin:0 0 20px 0}
.NewTitleBox h4{margin:0;font-weight:800;text-transform:capitalize;font-size:22px;color:#165885}
.NewTitleBox a{background-color:#165885;color:#fff !important;font-size:14px;font-weight:600;padding:10px 20px;border-radius:5px;display: inline-block;margin: 0px 0px 0px 5px;}
.NewTitleBox a:hover{background-color:#04bad2}
.NewTitleBox .ExcelDownload{background-color:#007193}
.NewTitleBox .ExcelDownload i{margin-right:7px}

.HotelLocation{text-align:right;margin:0 0 15px 0}
.HotelLocation aside h3{font-size:16px;color:#000;margin:0 0 4px 0;font-weight:700}
.HotelLocation aside p{font-size:13px;font-weight:500;color:#000}
.HotelLocation aside p span{background-color:#fff;width:25px;height:25px;display:inline-block;text-align:center;border-radius:50px;line-height:25px;margin:0 6px 0 0}

.CommonForm .form-group{margin:0 0 20px 0}
.CommonForm .form-group .form-group{margin:0 0 0 0}
.CommonForm .FormHeading{background-color:#ddd;padding:15px;margin:0 0 20px 0;border-radius:5px}
.CommonForm .FormHeading h4{font-weight:700;font-size:17px}
.CommonForm .form-group label{display:block;color:#000;font-weight:700;font-size:14px;text-transform:capitalize;margin:0 0 10px 0}
.CommonForm .form-group .form-control{height:auto;padding:10px 10px 10px 15px;border:1px solid #b3b3b3;border-radius:5px;box-shadow:none;font-weight:600;font-size:14px;position: relative;}
.CommonForm .form-group p a{ color:#000; font-weight:700; font-size:15px }
.CommonForm .form-group .Avaialable li{display:inline-block;width:32%}
.CommonForm .form-group .UploadBox{ display:flex; }
.CommonForm .form-group .UploadBox .Upload{border:2px dashed #b9b9b9;padding:10px 0;border-radius:5px;position:relative;display:flex;align-items:center;justify-content:center;margin: 0;min-width: 180px;height: 130px;text-align: center;}
.CommonForm .form-group .UploadBox .Upload i{font-size:24px}
.CommonForm .form-group .UploadBox .Upload span{font-size: 14px;font-weight:600;color:#000;text-transform:capitalize;margin: 10px 0 0 0px;display: block;}
.CommonForm .form-group .UploadBox .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;opacity:0}
.CommonForm .form-group .UploadBox figure{margin:0 auto 0;width:170px}

.CouponsList .CouponsBox{background-color:#f3f3f3;border:1px solid #ddd;padding:15px;border-radius:10px}
.CouponsList .CouponsBox figure{background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 4px #ddd}
.CouponsList .CouponsBox figure img{width:150px;margin:auto;display:block}
.CouponsList .CouponsBox figcaption h3{font-size:16px;font-weight:700;color:#000;margin:0 0 6px 0}
.CouponsList .CouponsBox figcaption h3 span{font-size:14px;color:#4e4e4e}
.CouponsList .CouponsBox figcaption h4{font-size:18px;font-weight:700;color:#609f0d;margin:0 0 5px 0}
.CouponsList .CouponsBox figcaption h6{margin:0 0 10px 0;font-weight:700;color:#000;font-size:14px}
.CouponsList .CouponsBox aside label{float:none}
.CouponsList .CouponsBox aside a{width:24px;display:inline-block;text-align:center}

.CouponsList .CouponsBox figcaption{
    margin-top: 10px;
}
.CouponsList .CouponsBox aside .Green{
    margin: 0;
}
.AmenitiesArea ul{display:flex;flex-wrap:wrap;margin:0 -10px}
.AmenitiesArea ul li{display:inline-block;width:calc(100% / 4);margin:0 0 20px;padding:0 10px}
.AmenitiesArea ul li .AmenitiesBox{padding:70px 20px 25px 20px;position:relative;overflow:hidden;text-align:center;background:#fff;border:1px solid #e8eef1;-webkit-transition:all 300ms linear;-o-transition:all 300ms linear;transition:all 300ms linear;border-radius:6px;-webkit-border-radius:6px;-moz-border-radius:6px;-ms-border-radius:6px;-o-border-radius:6px;-webkit-box-shadow:0 0 30px 0 rgb(0 0 0 / 5%);box-shadow:0 0 30px 0 rgb(0 0 0 / 5%);cursor:pointer;min-height:165px}
.AmenitiesArea ul li .AmenitiesBox aside{position:absolute;right:10px;top:10px}
.AmenitiesArea ul li .AmenitiesBox aside label{float:none;padding:0;width:40px;height:24px}
.AmenitiesArea ul li .AmenitiesBox aside a{width:30px;height:30px;display:inline-block;line-height:30px;border-radius:5px;margin:0 0 0 5px}
.AmenitiesArea ul li .AmenitiesBox aside a.Green{background-color:#00800021}
.AmenitiesArea ul li .AmenitiesBox aside a.Red{background-color:#ff000017}
.AmenitiesArea ul li .AmenitiesBox figure{width:60px;height:60px;margin:0 auto 15px}
.AmenitiesArea ul li .AmenitiesBox figure img{width:100%;height:100%}
.AmenitiesArea ul li .AmenitiesBox h6{margin:0;text-transform:capitalize;font-size:15px;font-weight:700;color:#000;line-height:23px}

.WifiArea{display:flex;flex-wrap:wrap;margin:0 -10px}
.WifiArea .WifiBox{background-color:#f1f1f1;padding:15px;border:1px solid #ddd;border-radius:10px;width:calc(100% / 3 - 10px);margin:0 5px 10px}
.WifiArea .WifiBox aside{display:flex;justify-content:space-between;margin:0 0 15px 0;align-items: center;}
.WifiArea .WifiBox aside h4{font-size:18px;font-weight:700}
.WifiArea .WifiBox aside a{width:30px;height:30px;display:inline-block;line-height:30px;border-radius:5px;margin:0 0 0 5px;text-align:center}
.WifiArea .WifiBox aside a.Green{background-color:#00800021}
.WifiArea .WifiBox aside a.Red{background-color:#ff000017}
.WifiArea .WifiBox article{background-color:#fff;padding:15px;border-radius:10px}
.WifiArea .WifiBox article p{font-size:14px;margin:0 0 10px 0}
.WifiArea .WifiBox article p:last-child{ margin:0 }
.WifiArea .WifiBox article p label{margin:0 15px 0 0;width: 110px;position:relative;color:#000;font-weight:700}
.WifiArea .WifiBox article p label:after{content:':';position:absolute;right:0}
.WifiArea .WifiBox article p span{font-weight:600}

.TrendingArea .TrendingBox{box-shadow:0 1px 4px #ddd;padding:20px;margin:0;border-radius:10px;display:flex;justify-content:space-between;background-color:#fff;margin:0 0 20px 0}
.TrendingArea .TrendingBox:last-child{margin:0}
.TrendingArea .TrendingBox article{display:flex}
.TrendingArea .TrendingBox article figure{margin:0;width:80px}
.TrendingArea .TrendingBox article figcaption{padding:0 0 0 20px}
.TrendingArea .TrendingBox article figcaption h3{font-weight:700;font-size:20px;color:#000;margin:0 0 7px 0}
.TrendingArea .TrendingBox article figcaption h4{font-size:14px;font-weight:700;margin:0 0 7px 0}
.TrendingArea .TrendingBox article figcaption h4 span{color:#29bd0e}
.TrendingArea .TrendingBox article figcaption h4 a{color:#4c4c4c;font-size:13px}
.TrendingArea .TrendingBox article figcaption p{font-size:14px;font-weight:600;color:#747474}
.TrendingArea .TrendingBox aside h5{color:#29bd0e;font-weight:700;font-size:18px;margin:0 0 5px 0}
.TrendingArea .TrendingBox aside h6{color:#000;font-weight:700;margin:0 0 10px 0}
.TrendingArea .TrendingBox aside button{background-color:#165885;color:#fff;font-size:14px;font-weight:600;padding:10px 20px;border-radius:5px;border:none;outline:0}

.RoomMiniBar article h5{font-weight:700;margin:0 0 20px 0;font-size:18px;color:#000}
.RoomMiniBar ul{display:flex;flex-wrap:wrap;margin:0 -7px}
.RoomMiniBar ul li{width:calc(100% / 2);padding:0 7px;margin:0 0 15px 0}
.RoomMiniBar ul li aside{display:flex;align-items:center;background-color:#fbfbfb;padding:20px;border:1px solid #ddd;border-radius:5px}
.RoomMiniBar ul li aside label{padding:0;width:25px;height:20px}
.RoomMiniBar ul li aside label .checkmark{background-color:#fff}
.RoomMiniBar ul li aside p{margin:0 10px;font-weight:700;color:#000;font-size:15px}
.RoomMiniBar ul li aside input{width:35px;height:35px;border:1px solid #ddd;border-radius:5px;text-align:center;font-size:14px;font-weight:700}
.RoomMiniBar .BarImages ol{display:flex;margin:0 -10px}
.RoomMiniBar .BarImages ol li{width:calc(100% /4);padding:0 8px}
.RoomMiniBar .BarImages ol li figure{margin:0;border:5px solid #fff;border-radius:10px;overflow:hidden;height:165px;box-shadow:0 0 10px #ddd}

.GuestsStayArea article{background-color:#f7f7f7;margin:0 0 20px 0;padding:20px;border:1px solid #ddd;border-radius:10px}
.GuestsStayArea article:last-child{margin:0}
.GuestsStayArea article h4{font-weight:700;color:#000;margin:0 0 15px 0;font-size:18px}
.GuestsStayArea article h4 a{color:#3bd10a;float:right;text-decoration:underline;font-style:italic}
.GuestsStayArea article ul{display:flex}
.GuestsStayArea article ul li{display:inline-block;margin:0 10px 0 0;min-width:300px}
.GuestsStayArea article ul li a{background-color:#fff;padding:20px 20px;height:100%;display:flex;align-items:center;justify-content:center;border:1px solid #ddd;border-radius:5px;min-height:120px}
.GuestsStayArea article ul li a h5{text-align:center;color:#000;font-weight:700;font-size:16px}
.GuestsStayArea article ul li a h5 span{display:block;font-size:12px;font-weight:600;color:#4c4c4c;margin:10px 0 0 0}

.GuestAccordian .card+.card{margin-top:20px}
.GuestAccordian .card .card-header{padding:15px;display:flex;justify-content:space-between;align-items:center;border-radius:0;border:none;cursor:pointer}
.GuestAccordian .card .card-header h5{font-weight:700;color:#3c3c3c;font-size:16px}
.GuestAccordian .card .card-header h5 strong{font-weight:700;color:#000}
.GuestAccordian .card .card-header h6{text-align:right;font-size:14px;font-weight:700;color:#000}
.GuestAccordian .card .card-header h6 span{display:block;color:#767676}
.GuestAccordian .card .card-body{border-top:1px solid #ddd}

.GuestUserBox{background-color:#f7f7f7;padding:10px;border:1px solid #ddd;border-radius:10px;display:flex;margin:0 0 20px 0}
.GuestUserBox:last-child{margin:0}
.GuestUserBox figure{width:65px;height:65px;margin:0}
.GuestUserBox figcaption{width:calc(100% - 65px);padding:0 0 0 10px}
.GuestUserBox figcaption .GuestUserHead{display:flex;position:relative}
.GuestUserBox figcaption .GuestUserHead .Approve{display:block;text-align:center;font-size:12px;color:#fff;font-weight:700;padding:8px 12px;border-radius:5px;margin:0 0 0 10px;border:none;background:#3fb50f;position:absolute;right:0;z-index:9}
.GuestUserBox figcaption .GuestUserHead aside{width:50%}
.GuestUserBox figcaption .GuestUserHead aside p{font-size:13px;margin:0 0 10px 0;display:flex}
.GuestUserBox figcaption .GuestUserHead aside p:last-child{margin:0}
.GuestUserBox figcaption .GuestUserHead aside p label{margin:0 10px 0 0;width:155px;position:relative;color:#000;font-weight:700}
.GuestUserBox figcaption .GuestUserHead aside p label:after{content:':';position:absolute;right:0;top:0}
.GuestUserBox figcaption .GuestUserHead aside p span{font-weight:600;width:calc(100% - 165px)}
.GuestUserBox figcaption .GuestUserBody{background-color:#fff;padding:15px;border:1px solid #ddd;border-radius:5px;margin:15px 0 0 0}
.GuestUserBox figcaption .GuestUserBody h6{font-weight:700;color:#000;margin:0 0 6px 0}
.GuestUserBox figcaption .GuestUserBody h6 strong{font-weight:800}
.GuestUserBox figcaption .GuestUserBody p{font-weight:700;margin:0 0 5px 0;color:#6a6a6a}
.GuestUserBox figcaption .GuestUserBody .form-group{margin:0;display:flex}
.GuestUserBox figcaption .GuestUserBody .form-group select{min-width:200px;border:1px solid #ddd;padding:10px 10px;font-weight:700;color:#000;border-radius:5px}
.GuestUserBox figcaption .GuestUserBody .form-group button{display:block;text-align:center;font-size:15px;color:#fff;font-weight:700;padding:5px 30px;border-radius:5px;margin:0 0 0 10px;border:none;background:#165885}

.modal-backdrop.show {
    opacity: .5 !important;
}

.modal.show{opacity: 1 !important;  }

.ModalBox .modal-dialog{max-width:450px;margin:50px auto;     transform: none !important;   }
.ModalBox .modal-dialog .CloseModal{position:absolute;top:-13px;right:-13px;width:26px;height:26px;background-color:#fff;border:2px solid rgba(0,0,0,.5);text-align:center;color:#000;font-size:15px;border-radius:50%}
.ModalBox .modal-dialog .Decline{margin:0}
.ModalBox .modal-dialog .Decline h3{font-weight: 800;font-size:22px;margin:0 0 5px;text-align:left;color: #000;}
.ModalBox .modal-dialog .Decline p{font-size:16px;color: #545353;text-align:left;font-weight: 600;}
.ModalBox .modal-dialog .Decline h4{margin:15px 0 0 0;text-align:right}
.ModalBox .modal-dialog .Decline h4 a{text-transform:capitalize;margin:0 0 0 30px;font-size:17px;color: #1970a5;font-weight: 600;-webkit-transition:0.5s all ease-in-out;transition:0.5s all ease-in-out}
.ModalBox .modal-dialog .Decline h4 a:hover{color:#000}

.ModalBox .modal-dialog .Category{padding:10px 10px}
.ModalBox .modal-dialog .Category h3{text-align:center;font-weight: 800;margin:0 0 20px 0;border-bottom:1px solid #ddd;padding:0 0 15px;font-size:20px}
.ModalBox .modal-dialog .Category .form-group{margin:0 0 20px;position:relative}
.ModalBox .modal-dialog .Category .form-group label{display:block;font-weight: 700;color:#000;font-size:14px;}
.ModalBox .modal-dialog .Category .form-group .form-control{box-shadow:none;border:1px solid #c3c3c3;font-size:14px;color:#000;line-height:22px;padding: 10px 10px;height: auto;font-weight: 600;}
.ModalBox .modal-dialog .Category .form-group textarea.form-control{height:auto}

.ModalBox .modal-dialog .Category .CheckinNew ul{}
.ModalBox .modal-dialog .Category .CheckinNew ul li a{display:block;text-align:center;font-size:14px;color:#000;font-weight:700;padding:15px 0;border-radius:5px}
.ModalBox .modal-dialog .Category .CheckinNew ul li p{text-align:center;font-size:14px;font-weight:700;padding:20px 0;position:relative}
.ModalBox .modal-dialog .Category .CheckinNew ul li p span{background-color:#fff;z-index:1;position:relative;padding:0 15px}
.ModalBox .modal-dialog .Category .CheckinNew ul li p:before{content:'';background-color:#d3d3d3;position:absolute;width:100%;height:1px;left:0;top:0;bottom:0;margin:auto}
.ModalBox .modal-dialog .Category .CheckinNew ul li:first-child a{background-color:#00cfd9}
.ModalBox .modal-dialog .Category .CheckinNew ul li:last-child a{background-color:#146eab;color:#fff}
.ModalBox .modal-dialog .Category .QRScanGuest h4{text-align:center;font-weight:800;font-size:18px}
.ModalBox .modal-dialog .Category .QRScanGuest figure{background-color:#ededed;padding:20px;border:1px solid #ddd;width:80%;margin:20px auto 20px}

.SuccessGuestBox{text-align:center;padding:30px}
.SuccessGuestBox span{display:block;width:160px;margin:0 auto 0}
.SuccessGuestBox h4{font-size:17px;color:#268d13;font-weight:700;line-height:32px;margin:0 0 17px 0}
.SuccessGuestBox a{background-color:#165885;padding:13px 40px;display:inline-block;border-radius:50px;color:#fff;font-weight:600;letter-spacing:.5px}


.top{
    display: flex;
    gap: 1rem;
}
  
  .back-icon {
    margin-left: 0.5rem;
    font-size: 1.875rem;
    color: #455A64;
    cursor: pointer;
  }
  
  
  .user-list {
    margin-left: 0;
  }

 
